















import { Component, Vue, Watch } from 'vue-property-decorator';
import CustomSelect from '@/modules/common/components/ui-kit/custom-select.vue';
import { Inject } from 'inversify-props';
import { BRAND_ANY, BRAND_BLANK } from '@/modules/cars/constants/car-filter-types.constant';
import SippSettingsService, { SippSettingsServiceS } from '@/modules/cars/modules/sipp/sipp-settings.service';
import StyledSearchbar from '@/modules/common/components/styled-searchbar.vue';

@Component({
    components: { CustomSelect, StyledSearchbar },
})
export default class CarBrandFilter extends Vue {
    @Inject(SippSettingsServiceS) private sippSettingsService!: SippSettingsService;

    @Watch('sippSettingsService.storeState.isFilterReseted', { deep: true })
    onResetFilters() {
        this.handleChange('');
    }

    private query: string | null = null;

    get items(): { name: string | string[]; value: string | string[] }[] {
        const { carBrands } = this.sippSettingsService.storeState.filters;

        let filteredBrands = carBrands;
        if (this.query) {
            const searchPattern = new RegExp(this.query.toLowerCase());
            filteredBrands = carBrands?.filter(brand => searchPattern.test(brand.toLowerCase()));
        }

        return [
            BRAND_ANY,
            BRAND_BLANK,
            ...(filteredBrands ? [...filteredBrands] : [])]
            .map(item => ({
                name: item,
                value: item,
            }));
    }

    get currentValue(): string {
        return this.sippSettingsService.carBrand || BRAND_ANY;
    }

    set currentValue(value: string) {
        this.sippSettingsService.carBrand = value || BRAND_ANY;
    }

    handleChange(query: string) {
        this.query = query;
        this.$emit('input', query);
    }
}
